export const LogoIcon = () => {
  return (
    <svg width="153" height="32" viewBox="0 0 153 32" fill="none">
      <path d="M6.37023 32C4.52683 32 3.00428 31.3875 1.80257 30.1624C0.600855 28.9374 0 27.4265 0 25.6298C0 23.8214 0.600855 22.3105 1.80257 21.0971C3.00428 19.8721 4.52683 19.2595 6.37023 19.2595C7.47861 19.2595 8.49948 19.522 9.43285 20.0471C10.3779 20.5604 11.1129 21.2604 11.6379 22.1471L9.55535 23.3547C9.25201 22.8063 8.82032 22.3805 8.2603 22.0771C7.70028 21.7621 7.07026 21.6046 6.37023 21.6046C5.18019 21.6046 4.21765 21.978 3.48263 22.7247C2.75927 23.4714 2.39759 24.4397 2.39759 25.6298C2.39759 26.8081 2.75927 27.7707 3.48263 28.5174C4.21765 29.2641 5.18019 29.6374 6.37023 29.6374C7.07026 29.6374 7.70028 29.4857 8.2603 29.1824C8.83199 28.8674 9.26367 28.4415 9.55535 27.9049L11.6379 29.1124C11.1129 29.9991 10.3837 30.705 9.45035 31.23C8.51698 31.7433 7.49027 32 6.37023 32Z" fill="#EEF6F6"/>
      <path d="M16.0227 29.4449H21.1854V31.755H13.6076V19.5045H21.0979V21.8146H16.0227V24.4222H20.6604V26.6973H16.0227V29.4449Z" fill="#EEF6F6"/>
      <path d="M30.4617 19.5045H32.8767V31.755H31.0392L25.789 24.2647V31.755H23.3739V19.5045H25.2115L30.4617 26.9773V19.5045Z" fill="#EEF6F6"/>
      <path d="M43.7382 19.5045V21.8146H40.4305V31.755H38.0154V21.8146H34.7253V19.5045H43.7382Z" fill="#EEF6F6"/>
      <path d="M52.2928 31.755L49.8252 27.5023H47.9876V31.755H45.5725V19.5045H50.4727C51.6044 19.5045 52.5669 19.9012 53.3603 20.6946C54.1537 21.4879 54.5503 22.4447 54.5503 23.5647C54.5503 24.3231 54.3345 25.0289 53.9028 25.6823C53.4711 26.324 52.8995 26.8081 52.1878 27.1348L54.9004 31.755H52.2928ZM47.9876 21.7621V25.3848H50.4727C50.9277 25.3848 51.3186 25.2098 51.6452 24.8597C51.9719 24.4981 52.1353 24.0664 52.1353 23.5647C52.1353 23.063 51.9719 22.6372 51.6452 22.2871C51.3186 21.9371 50.9277 21.7621 50.4727 21.7621H47.9876Z" fill="#EEF6F6"/>
      <path d="M61.2908 32C59.9257 32 58.8057 31.615 57.9306 30.845C57.0556 30.0749 56.6181 29.0424 56.6181 27.7473V19.5045H59.0157V27.5548C59.0157 28.1965 59.1965 28.704 59.5582 29.0774C59.9316 29.4507 60.5091 29.6374 61.2908 29.6374C62.0725 29.6374 62.6441 29.4507 63.0058 29.0774C63.3792 28.704 63.5658 28.1965 63.5658 27.5548V19.5045H65.9809V27.7473C65.9809 29.0424 65.5434 30.0749 64.6684 30.845C63.7934 31.615 62.6675 32 61.2908 32Z" fill="#EEF6F6"/>
      <path d="M80.6307 19.5045V31.755H78.2331V23.8097L74.7855 29.4974H74.5055L71.0578 23.8272V31.755H68.6427V19.5045H71.1278L74.6455 25.2973L78.1456 19.5045H80.6307Z" fill="#EEF6F6"/>
      <path d="M94.981 31.755L92.5134 27.5023H90.6758V31.755H88.2607V19.5045H93.1609C94.2926 19.5045 95.2551 19.9012 96.0485 20.6946C96.8419 21.4879 97.2385 22.4447 97.2385 23.5647C97.2385 24.3231 97.0227 25.0289 96.591 25.6823C96.1593 26.324 95.5876 26.8081 94.8759 27.1348L97.5885 31.755H94.981ZM90.6758 21.7621V25.3848H93.1609C93.6159 25.3848 94.0068 25.2098 94.3334 24.8597C94.6601 24.4981 94.8235 24.0664 94.8235 23.5647C94.8235 23.063 94.6601 22.6372 94.3334 22.2871C94.0068 21.9371 93.6159 21.7621 93.1609 21.7621H90.6758Z" fill="#EEF6F6"/>
      <path d="M108.85 19.5045L104.65 26.9773V31.755H102.253V26.9773L98.0699 19.5045H100.8L103.46 24.6147L106.12 19.5045H108.85Z" fill="#EEF6F6"/>
      <path d="M117.913 25.4023C118.987 26.0206 119.523 26.954 119.523 28.2024C119.523 29.2407 119.156 30.0924 118.421 30.7575C117.686 31.4225 116.782 31.755 115.708 31.755H110.511V19.5045H115.341C116.391 19.5045 117.272 19.8312 117.983 20.4846C118.707 21.1263 119.068 21.9488 119.068 22.9522C119.068 23.9672 118.683 24.7839 117.913 25.4023ZM115.341 21.7621H112.926V24.4572H115.341C115.714 24.4572 116.023 24.3289 116.268 24.0722C116.525 23.8155 116.653 23.4947 116.653 23.1097C116.653 22.7247 116.531 22.4038 116.286 22.1471C116.041 21.8905 115.726 21.7621 115.341 21.7621ZM115.708 29.4974C116.117 29.4974 116.455 29.3632 116.723 29.0949C116.992 28.8149 117.126 28.4649 117.126 28.0449C117.126 27.6365 116.992 27.2982 116.723 27.0298C116.455 26.7498 116.117 26.6098 115.708 26.6098H112.926V29.4974H115.708Z" fill="#EEF6F6"/>
      <path d="M129.468 31.755L128.733 29.5499H123.85L123.115 31.755H120.508L124.795 19.5045H127.788L132.093 31.755H129.468ZM124.62 27.2923H127.981L126.3 22.2871L124.62 27.2923Z" fill="#EEF6F6"/>
      <path d="M140.489 31.755L138.021 27.5023H136.184V31.755H133.769V19.5045H138.669C139.801 19.5045 140.763 19.9012 141.557 20.6946C142.35 21.4879 142.747 22.4447 142.747 23.5647C142.747 24.3231 142.531 25.0289 142.099 25.6823C141.667 26.324 141.096 26.8081 140.384 27.1348L143.097 31.755H140.489ZM136.184 21.7621V25.3848H138.669C139.124 25.3848 139.515 25.2098 139.842 24.8597C140.168 24.4981 140.332 24.0664 140.332 23.5647C140.332 23.063 140.168 22.6372 139.842 22.2871C139.515 21.9371 139.124 21.7621 138.669 21.7621H136.184Z" fill="#EEF6F6"/>
      <path d="M147.42 29.4449H152.583V31.755H145.005V19.5045H152.495V21.8146H147.42V24.4222H152.058V26.6973H147.42V29.4449Z" fill="#EEF6F6"/>
      <path d="M7.08776 0.203187H9.50285V12.4536H7.66528L2.41509 4.96336V12.4536H0V0.203187H1.83757L7.08776 7.67596V0.203187Z" fill="#275B6A"/>
      <path d="M29.0887 12.4536L24.976 0.203187H27.6011L30.5937 9.61853L33.5688 0.203187H36.2114L32.0813 12.4536H29.0887Z" fill="#275B6A"/>
      <path d="M40.2933 10.1436H45.456V12.4536H37.8782V0.203187H45.3685V2.51327H40.2933V5.12087H44.9309V7.39595H40.2933V10.1436Z" fill="#275B6A"/>
      <path d="M17.5006 0C14.8204 0 12.6394 2.1504 12.6394 4.79423C12.6394 8.55081 17.0437 12.4296 17.2311 12.5925C17.3052 12.6574 17.4011 12.6933 17.5005 12.6934C17.6 12.6935 17.696 12.6578 17.7702 12.593C17.9576 12.4296 22.3619 8.55081 22.3619 4.79423C22.3619 2.1504 20.1808 0 17.5006 0ZM17.5006 7.40421C16.0115 7.40421 14.7999 6.21795 14.7999 4.75985C14.7999 3.30175 16.0115 2.11549 17.5006 2.11549C18.9898 2.11549 20.2014 3.30175 20.2014 4.75985C20.2014 6.21795 18.9898 7.40421 17.5006 7.40421Z" fill="#EEF6F6"/>
    </svg>
  )
};

export const AdminLogoIcon = () => {
  return (
    <svg width="141" height="30" viewBox="0 0 141 30" fill="none">
      <path d="M5.86391 29.728C4.16703 29.728 2.76549 29.1642 1.65929 28.0365C0.553098 26.9088 0 25.518 0 23.8641C0 22.1995 0.553098 20.8087 1.65929 19.6917C2.76549 18.564 4.16703 18.0002 5.86391 18.0002C6.88418 18.0002 7.82391 18.2419 8.68309 18.7251C9.55302 19.1977 10.2296 19.8421 10.7129 20.6583L8.79586 21.7699C8.51663 21.2651 8.11926 20.8731 7.60375 20.5939C7.08824 20.3039 6.50829 20.1589 5.86391 20.1589C4.76845 20.1589 3.88242 20.5026 3.20582 21.1899C2.53995 21.8773 2.20702 22.7687 2.20702 23.8641C2.20702 24.9488 2.53995 25.8349 3.20582 26.5222C3.88242 27.2096 4.76845 27.5532 5.86391 27.5532C6.50829 27.5532 7.08824 27.4136 7.60375 27.1344C8.13 26.8444 8.52737 26.4524 8.79586 25.9584L10.7129 27.0699C10.2296 27.8862 9.55838 28.5359 8.6992 29.0192C7.84002 29.4918 6.89492 29.728 5.86391 29.728Z" fill="black"/>
      <path d="M14.7492 27.376H19.5015V29.5025H12.5261V18.2257H19.421V20.3522H14.7492V22.7526H19.0183V24.8468H14.7492V27.376Z" fill="black"/>
      <path d="M28.0405 18.2257H30.2636V29.5025H28.5721L23.7392 22.6076V29.5025H21.5161V18.2257H23.2076L28.0405 25.1046V18.2257Z" fill="black"/>
      <path d="M40.2617 18.2257V20.3522H37.217V29.5025H34.9939V20.3522H31.9653V18.2257H40.2617Z" fill="black"/>
      <path d="M48.1364 29.5025L45.8649 25.5879H44.1734V29.5025H41.9503V18.2257H46.461C47.5027 18.2257 48.3888 18.5909 49.1191 19.3212C49.8494 20.0515 50.2145 20.9322 50.2145 21.9632C50.2145 22.6613 50.0158 23.311 49.6185 23.9124C49.2211 24.5031 48.6948 24.9488 48.0397 25.2495L50.5367 29.5025H48.1364ZM44.1734 20.3039V23.6386H46.461C46.8798 23.6386 47.2396 23.4775 47.5403 23.1553C47.841 22.8224 47.9914 22.425 47.9914 21.9632C47.9914 21.5014 47.841 21.1094 47.5403 20.7872C47.2396 20.465 46.8798 20.3039 46.461 20.3039H44.1734Z" fill="black"/>
      <path d="M56.4192 29.728C55.1626 29.728 54.1316 29.3736 53.3261 28.6648C52.5207 27.956 52.1179 27.0055 52.1179 25.8134V18.2257H54.3249V25.6362C54.3249 26.2269 54.4914 26.694 54.8243 27.0377C55.168 27.3814 55.6996 27.5532 56.4192 27.5532C57.1388 27.5532 57.665 27.3814 57.9979 27.0377C58.3416 26.694 58.5134 26.2269 58.5134 25.6362V18.2257H60.7366V25.8134C60.7366 27.0055 60.3338 27.956 59.5284 28.6648C58.7229 29.3736 57.6865 29.728 56.4192 29.728Z" fill="black"/>
      <path d="M74.2219 18.2257V29.5025H72.0149V22.1887L68.8413 27.4244H68.5835L65.41 22.2048V29.5025H63.1868V18.2257H65.4744L68.7124 23.558L71.9343 18.2257H74.2219Z" fill="black"/>
      <path d="M87.4316 29.5025L85.1601 25.5879H83.4686V29.5025H81.2455V18.2257H85.7562C86.7979 18.2257 87.684 18.5909 88.4143 19.3212C89.1446 20.0515 89.5097 20.9322 89.5097 21.9632C89.5097 22.6613 89.311 23.311 88.9137 23.9124C88.5163 24.5031 87.9901 24.9488 87.3349 25.2495L89.8319 29.5025H87.4316ZM83.4686 20.3039V23.6386H85.7562C86.175 23.6386 86.5348 23.4775 86.8355 23.1553C87.1362 22.8224 87.2866 22.425 87.2866 21.9632C87.2866 21.5014 87.1362 21.1094 86.8355 20.7872C86.5348 20.465 86.175 20.3039 85.7562 20.3039H83.4686Z" fill="black"/>
      <path d="M100.199 18.2257L96.3322 25.1046V29.5025H94.1252V25.1046L90.275 18.2257H92.7881L95.2368 22.9298L97.6854 18.2257H100.199Z" fill="black"/>
      <path d="M108.541 23.6547C109.529 24.2239 110.023 25.0831 110.023 26.2322C110.023 27.1881 109.685 27.9721 109.008 28.5842C108.332 29.1964 107.5 29.5025 106.511 29.5025H101.727V18.2257H106.173C107.14 18.2257 107.951 18.5265 108.606 19.1279C109.272 19.7186 109.604 20.4757 109.604 21.3993C109.604 22.3337 109.25 23.0855 108.541 23.6547ZM106.173 20.3039H103.95V22.7848H106.173C106.517 22.7848 106.801 22.6666 107.027 22.4304C107.263 22.1941 107.381 21.8987 107.381 21.5443C107.381 21.1899 107.269 20.8946 107.043 20.6583C106.818 20.422 106.528 20.3039 106.173 20.3039ZM106.511 27.4244C106.887 27.4244 107.199 27.3008 107.446 27.0538C107.693 26.7961 107.816 26.4739 107.816 26.0872C107.816 25.7114 107.693 25.3999 107.446 25.1529C107.199 24.8951 106.887 24.7663 106.511 24.7663H103.95V27.4244H106.511Z" fill="black"/>
      <path d="M119.178 29.5025L118.501 27.4727H114.006L113.33 29.5025H110.929L114.876 18.2257H117.631L121.594 29.5025H119.178ZM114.715 25.3945H117.808L116.262 20.7872L114.715 25.3945Z" fill="black"/>
      <path d="M129.323 29.5025L127.051 25.5879H125.36V29.5025H123.136V18.2257H127.647C128.689 18.2257 129.575 18.5909 130.305 19.3212C131.036 20.0515 131.401 20.9322 131.401 21.9632C131.401 22.6613 131.202 23.311 130.805 23.9124C130.407 24.5031 129.881 24.9488 129.226 25.2495L131.723 29.5025H129.323ZM125.36 20.3039V23.6386H127.647C128.066 23.6386 128.426 23.4775 128.727 23.1553C129.027 22.8224 129.178 22.425 129.178 21.9632C129.178 21.5014 129.027 21.1094 128.727 20.7872C128.426 20.465 128.066 20.3039 127.647 20.3039H125.36Z" fill="black"/>
      <path d="M135.703 27.376H140.455V29.5025H133.479V18.2257H140.374V20.3522H135.703V22.7526H139.972V24.8468H135.703V27.376Z" fill="black"/>
      <path d="M6.5244 0.458521H8.74753V11.7353H7.05602L2.22313 4.84034V11.7353H0V0.458521H1.69151L6.5244 7.33734V0.458521Z" fill="#3A859A"/>
      <path d="M26.7766 11.7353L22.9909 0.458521H25.4073L28.1621 9.12551L30.9007 0.458521H33.3332L29.5314 11.7353H26.7766Z" fill="#3A859A"/>
      <path d="M37.0906 9.6088H41.843V11.7353H34.8675V0.458521H41.7624V2.58499H37.0906V4.98533H41.3597V7.07958H37.0906V9.6088Z" fill="#3A859A"/>
      <path d="M16.1096 0.271484C13.6425 0.271484 11.6347 2.25096 11.6347 4.68465C11.6347 8.14265 15.689 11.7131 15.8615 11.8631C15.9297 11.9228 16.018 11.9559 16.1095 11.956C16.2011 11.9561 16.2894 11.9232 16.3577 11.8635C16.5303 11.7131 20.5845 8.14265 20.5845 4.68465C20.5845 2.25096 18.5768 0.271484 16.1096 0.271484ZM16.1096 7.08719C14.7388 7.08719 13.6236 5.99522 13.6236 4.65301C13.6236 3.3108 14.7388 2.21883 16.1096 2.21883C17.4804 2.21883 18.5957 3.3108 18.5957 4.65301C18.5957 5.99522 17.4804 7.08719 16.1096 7.08719Z" fill="black"/>
    </svg>
  )
};

export const LogoutIcon = ({ color = "#262626"}) => {
  return (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M19 4.72852L14 4.72852V2.72852L19 2.72852C20.6569 2.72852 22 4.07166 22 5.72852V19.7285C22 21.3854 20.6569 22.7285 19 22.7285H14V20.7285H19C19.5523 20.7285 20 20.2808 20 19.7285V5.72852C20 5.17623 19.5523 4.72852 19 4.72852ZM10.7071 7.02141L16.4142 12.7285L10.7071 18.4356L9.29289 17.0214L12.5858 13.7285L2 13.7285V11.7285L12.5858 11.7285L9.29289 8.43562L10.7071 7.02141Z"
        fill={color}
      />
    </svg>
  )
};

export const ArrowUpIcon = ({color = "#00000099"}) => {
  return (
    <svg width="40" height="41" viewBox="0 0 40 41" fill="none">
      <path
        d="M6.66699 20.8725L9.01699 23.2225L18.3337 13.9225V34.2058H21.667V13.9225L30.9837 23.2225L33.3337 20.8725L20.0003 7.53914L6.66699 20.8725Z"
        fill={color}
      />
    </svg>
  )
};

export const ArrowDownIcon = ({color = "#00000099"}) => {
  return (
    <svg width="40" height="41" viewBox="0 0 40 41" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M33.333 20.8726L30.983 18.5226L21.6663 27.8226L21.6663 7.53931L18.333 7.53931L18.333 27.8226L9.01634 18.5226L6.66634 20.8726L19.9997 34.206L33.333 20.8726Z"
        fill={color}
      />
    </svg>
  )
};

export const EmailIcon = ({color = "#fff"}) => {
  return (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2 5.35767H22V7.1216L12 12.1216L2 7.1216V5.35767ZM0 5.35767C0 4.2531 0.895431 3.35767 2 3.35767H22C23.1046 3.35767 24 4.2531 24 5.35767V8.35767V18.3577C24 20.0145 22.6569 21.3577 21 21.3577H3C1.34314 21.3577 0 20.0145 0 18.3577V8.35767V5.35767ZM2 9.35767V18.3577C2 18.91 2.44772 19.3577 3 19.3577H21C21.5523 19.3577 22 18.91 22 18.3577V9.35767L12 14.3577L2 9.35767Z"
        fill={color}
      />
    </svg>
  )
};

export const CloseIcon = ({color = "#fff"}) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd" d="M21 4.38462L13.3846 12L21 19.6154L19.6154 21L12 13.3846L4.38462 21L3 19.6154L10.6154 12L3 4.38462L4.38462 3L12 10.6154L19.6154 3L21 4.38462Z"
        fill={color}
      />
    </svg>
  )
};