import React from "react";
import ContentPage from "./pages/ContentPage";

function App() {
  return (
    <ContentPage />
  );
}

export default App;
