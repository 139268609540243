export const objects = [
  {
    id: 1,
    type: 'Жилых квартир',
    description: 'Несколько домов с 7 этажами',
    count: '100+'
  },
  {
    id: 2,
    type: 'Супермаркет',
    description: 'Инвестор ведет переговоры с несколькими крупными брендами.',
    count: '800+ м2'
  },
  {
    id: 3,
    type: 'Парковочных мест',
    description: 'Подземные этажи для парковки',
    count: '100+'
  },
  {
    id: 4,
    type: 'Коммерческих помещений',
    description: 'Так же будут большие и небольшие помещения для аренды',
    count: '1500+ м2'
  },
];